<template>
  <div>
    <b-row>
      <b-col>
        <!-- <b-dropdown
          id="dropdown-1"
          class="float-right"
          :text="lang('t_sendEmail')"
          variant="flat-primary"
        >
          <template #button-content>
            {{ lang("t_sendEmail") }}
            <i class="bi bi-send ml-1"></i>
          </template> -->
        <!-- <b-dropdown-item
            @click="
              () => {
                sender_info.type = item.key;
                modal_send = true;
              }
            "
            v-for="item in types"
            :key="item.key"
          >
            {{ item.value }}
          </b-dropdown-item> -->

        <!-- </b-dropdown> -->
        <b-button
          @click="openSendEmailModal"
          class="float-right"
          variant="flat-primary"
        >
          {{ lang("t_sendEmail") }}
          <i class="bi bi-send ml-1"></i>
        </b-button>
      </b-col>
    </b-row>

    <email-template></email-template>
    <email-filter></email-filter>

    <b-card-text v-if="false">
      <b-row>
        <b-col cols="12">
          <div class="mx-auto" max-width="100%">
            <b-card>
              <b-row>
                <b-col class="pb-0" cols="12">
                  <b-row>
                    <!-- Gönderim Seçenekleri -->
                    <b-col cols="12" class="mb-50">
                      <b-form-group>
                        <v-select
                          v-model="sender_info.type"
                          :options="types"
                          label="value"
                          :reduce="(item) => item.key"
                          :placeholder="lang('t_sendType')"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <system-sms
                v-if="sender_info.type == 'system_email'"
              ></system-sms>
              <external-sms
                v-if="sender_info.type == 'external_email'"
              ></external-sms>
              <special-sms
                v-if="sender_info.type == 'special_email'"
              ></special-sms>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-card-text>
    <b-modal
      dialog-class="customModalSize"
      :title="lang('t_sendEmail')"
      v-model="send_email_modal"
      centered
      size="lg"
      :ok-title="lang('t_send')"
      ok-only
      @ok="sendMail"
    >
      <b-form-group :label="lang('t_account')" label-for="t_account">
        <v-select
          v-model="mail_object.provider"
          :options="providers"
          label="display_name"
        >
        </v-select>
      </b-form-group>
      <b-form-group :label="lang('t_to')" label-for="to">
        <b-form-input
          v-model="mail_object.to"
          id="to"
          :placeholder="lang('t_youCanAddCommaBetweenEmailAddressesToAddMore')"
          trim
        />
      </b-form-group>
      <b-form-group :label="lang('t_cc')" label-for="cc">
        <b-form-input
          v-model="mail_object.cc"
          id="cc"
          :placeholder="lang('t_youCanAddCommaBetweenEmailAddressesToAddMore')"
          trim
        />
      </b-form-group>
      <b-form-group :label="lang('t_subject')" label-for="subject">
        <b-form-input
          v-model="mail_object.subject"
          id="subject"
          :placeholder="lang('t_subject')"
          trim
        />
      </b-form-group>
      <b-form-group :label="lang('t_text')" label-for="text">
        <quill-editor v-model="mail_object.body" :options="snowOption" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";

import EmailTemplate from '../EMailTemplate/list/List.vue'
import EmailFilter from '../../SMS/SmsFilter/list/List.vue'
import SystemEMail from "./EMailSend/SystemEMail.vue";
import ExternalEMail from "./EMailSend/ExternalEMail.vue";
import SpecialEMail from "./EMailSend/SpecialEMail.vue";
import vSelect from "vue-select";
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
    EmailTemplate,
    EmailFilter,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BLink,
    BBadge,
    BCardText,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    SystemEMail,
    SpecialEMail,
    ExternalEMail,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      sender_info: {
        type: "",
      },
      types: [
        {
          key: "system_email",
          value: globalThis._lang("t_sendFromVoyceCRM"),
        },
        {
          key: "external_email",
          value: globalThis._lang("t_sendFromExternalCRM"),
        },
        {
          key: "special_email",
          value: globalThis._lang("t_sendFromCustomSms"),
        },
      ],
      send_email_modal: false,
      providers: [],
      mail_object: {
        provider: null,
        to: '',
        cc: '',
        subject: '',
        body: '',
      },
      snowOption: {
        placeholder: '',// globalThis._lang('t_text'),
        theme: 'snow'

      },
    };
  },
  methods: {
    async sendMail() {
      const result = (await this.$http_in.post(`spv/v1/SendManualMail`, { ...this.mail_object })).data;
      console.log(result);
    },
    openSendEmailModal() {
      this.send_email_modal = true;
      this.mail_object = {
        provider: null,
        to: '',
        cc: '',
        subject: '',
        body: '',
      };
    },
    getEMailProviders: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/EMailSettings`)).data;
      this.providers = response;
    },
  },
  mounted: function () {
    this.getEMailProviders();
  },
};
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";

.customModalSize {
  min-width: 75rem;
}
</style>

<template>
  <div>
    <b-card-text>
      <b-row>
        <b-col cols="12">
          <!-- Operatör -->
          <b-form-group>
            <v-select
              v-model="filter_values.selectedtOperator"
              :options="operators"
              :placeholder="lang('t_operator')"
              :reduce="(val) => val"
              label="display_name"
            >
            </v-select>
          </b-form-group>
          <!-- Proje -->
          <b-form-group>
            <v-select
              v-model="filter_values.selectedtProject"
              :options="projects"
              :reduce="(val) => val"
              label="display_name"
              :placeholder="lang('t_project')"
            >
            </v-select>
          </b-form-group>
          <!-- Mesaj ve Filtre -->
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  v-if="filter_values.selectedtProject.internal_name != ''"
                  :options="
                    templates.filter((e) => {
                      return (
                        e.project ==
                        filter_values.selectedtProject.internal_name
                      );
                    })
                  "
                  v-model="filter_values.selectedMessageTemplate"
                  :reduce="(val) => val"
                  :placeholder="lang('t_smsPattern')"
                  label="display_name"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  v-model="filter_values.selectedFilters"
                  :options="
                    filters.filter((e) => {
                      return (
                        e.project ==
                        filter_values.selectedtProject.internal_name
                      );
                    })
                  "
                  label="display_name"
                  :placeholder="lang('t_filter')"
                >
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-textarea
                v-if="show_textarea"
                v-model="filter_values.selectedMessageTemplate.message"
                class="fontFeatures mb-1"
                :label="lang('t_exampleText')"
                rows="12"
                readonly
                disabled
              >
              </b-form-textarea>
            </b-col>
          </b-row>
          <!-- Butonlar -->
          <b-button
            @click="special_dialog()"
            :disabled="preview_click == false"
            variant="success"
            style="float: right"
          >
            {{ lang("t_send") }}
          </b-button>
          <b-button
            variant="gradient-primary"
            style="float: right; margin-right: 10px"
            class="ml-1"
            :disabled="filter_values.selectedMessageTemplate == ''"
            @click="GetSmsData"
          >
            {{ lang("t_preview") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardText, BLink, BRow, BCol, BFormGroup, BFormCheckbox, BFormInput, BButton, BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BLink,
    BBadge,
    BCardText,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    BFormTextarea,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      filter_values: {
        selectedtOperator: [],
        selectedtProject: [],
        selectedMessageTemplate: [],
        selectedFilters: [],
      },

      operators: [],
      filters: [],
      projects: [],
      campaigns: [],
      templates: [],
      filtered_data: [],
      smsdata: [],
      smsexceldata: [],
      columns: [],
      show_textarea: false,
      preview_message: '',
      preview_click: false,

    }
  },
  mounted: function () {
    this.get_smsfilters();
    this.get_smstemplate();
    this.get_projects();
    this.get_smssettings();
  },
  methods: {
    mongoize_campaign_filters(filters) {
      if (![null, undefined].includes(filters)) {
        var _mongoized_filters = Object.create(null);
        for (var f of filters) {
          if (["<", "<=", ">", ">="].includes(f.operand)) {
            if (isNaN(f.value)) {

            } else {
              f.value = Number(f.value);
            }

            if (f.column in _mongoized_filters) {
              _mongoized_filters[f.column] = { ..._mongoized_filters[f.column], [operands[f.operand]]: f.value };
            }
            else {
              _mongoized_filters[f.column] = { [operands[f.operand]]: f.value };
            }
          }
          if (["="].includes(f.operand)) {
            if (isNaN(f.value)) {

            } else {
              f.value = Number(f.value);
            }

            if (f.column in _mongoized_filters) {
              _mongoized_filters[f.column]["$in"].push(`${f.value}`);
            }
            else {
              _mongoized_filters[f.column] = { "$in": [`${f.value}`] };
            }
          }
        }
        return _mongoized_filters;
      }
      return null;
    },
    special_dialog() {
      if (this.filter_values.selectedFilters.length <= 0) {
        this.$swal({
          icon: 'error',
          title: this.lang('t_description'),
          text: this.lang('t_noFilterHasBeenSelected'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      } else {
        this.$swal({
          title: this.lang('t_areYouSure'),
          text: this.filtered_data.length + this.lang('t_smscount'),
          //  {{ `${special_info.length} adet mesaj gönderilecektir. Onaylıyor musunuz?` }}
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.lang('t_yes'),
          cancelButtonText: this.lang('t_cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',

          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.send_sms();
          }
        });
      }
    },
    send_sms: async function () {

      let messages = '';
      let phones = '';

      for (const row of this.filtered_data) {
        let _message = '';
        _message = this.preview_message;

        for (const key of Object.keys(row)) {
          _message = _message.replace('{{' + key + '}}', row[key]);
        }

        if (row['phones'].Telefon.substr(-10)[0] == '5') {
          phones = '90' + row['phones'].Telefon.substr(-10);
          messages = _message.trim();
        }

        this.smsdata = {
          messages: messages.trim(),
          phones: phones,
        };

        console.log('smsdata', this.smsdata);
        var response = (
          await this.$http_in.post(
            `spv/v1/SendSms`,
            {
              data: this.smsdata,
              sending_info: {
                ...this.filter_values,
                sms_settings: this.filter_values.selectedtOperator,
                collection: `${this.filter_values.selectedtProject.internal_name}_customers`,
                template: this.filter_values.selectedMessageTemplate.internal_name,
                filter: this.filter_values.selectedFilters.internal_name
              }
            },

          )
        ).data;
      }

      console.log("response.status", response.status);
      if (response.status == 'success') {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: this.lang('t_messagesHaveBeenDeliveredSuccessfully'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });

      } else {
        this.$swal({
          icon: 'warning',
          title: this.lang('t_warning'),
          text: this.lang('t_thereWasAProblemDeliveringYourMessages'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }
    },
    GetSmsData: async function () {
      // console.log("filter_values.selectedFilters", this.filter_values.selectedFilters);
      //  console.log("filter_values.selectedMessageTemplate", this.filter_values.selectedMessageTemplate);
      //  console.log("asd",  `${this.filter_values.selectedtProject.internal_name}_customers`);

      var filter = this.mongoize_campaign_filters(this.filter_values.selectedFilters.filters);
      var response = (
        await this.$http_in.post(
          `spv/v1/SmsData`,
          {
            filter: filter,
            collection: `${this.filter_values.selectedtProject.internal_name}_customers`
          }
        )
      ).data;

      this.preview_message = this.filter_values.selectedMessageTemplate.message;
      console.log("preview_message", this.preview_message);
      if (response.length > 0) {
        for (const key of Object.keys(response[0])) {
          this.filter_values.selectedMessageTemplate.message = this.filter_values.selectedMessageTemplate.message.replace(
            '{{' + key + '}}',
            response[0][key]
          );
        }
        this.show_textarea = true;
      }

      this.filtered_data = response;
      console.log("filtered_data", this.filtered_data);
      this.preview_click = true;
    },
    get_smssettings: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsSettings`)
      ).data;

      this.operators = response;
    },
    get_smsfilters: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsFilter`)
      ).data;
      //this.columns = response;
      this.filters = response;
    },
    get_smstemplate: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsTemplate`)
      ).data;
      //this.columns = response;
      this.templates = response;
    },

    get_projects: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/Project`)
      ).data;
      this.projects = response;
    },
  },

}
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

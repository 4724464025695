<template>
  <div>
    <e-mail-sender></e-mail-sender>

    <!-- <b-card> -->

    <!-- <sms-sender v-if="selectedButton == 'sender'"></sms-sender> -->
    <!-- <e-mail-template v-if="selectedButton == 'template'"></e-mail-template> -->
    <!-- <e-mail-filter v-if="selectedButton == 'filter'"></e-mail-filter> -->
    <!-- <e-mail-settings v-if="selectedButton == 'settings'"></e-mail-settings> -->
    <!-- </b-card> -->
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
} from "bootstrap-vue";
import EMailSender from "./EMailSender/Sender.vue";
import EMailFilter from "./EMailFilter/list/List.vue";
import EMailTemplate from "./EMailTemplate/list/List.vue";

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      selectedButton: "template",
      optionsButton: [
        { text: globalThis._lang("t_eMailSending"), value: "sender" },
        { text: globalThis._lang("t_eMailTemplate"), value: "template" },
        { text: globalThis._lang("t_eMailFilter"), value: "filter" },
      ],
    };
  },
  components: {
    EMailFilter,
    EMailTemplate,
    EMailSender,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
  },
};
</script>

<style>
</style>
